import "./App.css";
import { initialise, useStore } from "./state";

import pkg from "../package.json";

initialise();
function App() {
  const store = useStore();
  if (!store.initialised) {
    return <div className="loading-container">Loading...</div>;
  }
  if (store.showStats) {
    return (
      <div className="loading-container">
        total images {store.imageIds.length}. version {pkg.version}
      </div>
    );
  }
  const { currentImage } = store;
  const images = [currentImage].filter((x) => !!x);
  return (
    <div className="App">
      <header className="App-header">
        {images.map((image) => (
          <div className="image-container">
            <div className="counter">{store.counter}</div>
            <img key={image} className="image" src={image} alt="female model" />
          </div>
        ))}
        {/* {!!currentImage && ( */}
        {/*   <img className="image" key={currentImage} src={currentImage} alt="image" /> */}
        {/* )} */}
        {/* {!!nextImage && <img key={nextImage} className="image" src={nextImage} alt="image" />} */}
      </header>
    </div>
  );
}

export default App;
