import Dexie from "dexie";
import { create } from "zustand";
export interface Image {
  id: string;
}
export interface ImageBlob {
  id: string;
  data: Blob;
}
export interface State {
  initialised: boolean;
  counter: number;
  showStats: boolean;
  imageIds: string[];
  currentImage: string;
  nextImage: string;
  init: (args: {
    imageIds: string[];
    currentImage: string;
    nextImage: string;
  }) => void;
  addNextImage: (imageUrl: string) => void;
  setShowStats: (showSats: boolean) => void;
}

export const useStore = create<State>()((set) => ({
  initialised: false,
  showStats: false,
  imageIds: [],
  counter: 1,
  currentImage: "",
  nextImage: "",
  setShowStats(showSats: boolean) {
    set({ showStats: showSats });
  },
  init: (args) => {
    set({
      initialised: true,
      imageIds: args.imageIds,
      currentImage: args.currentImage,
      nextImage: args.nextImage,
    });
  },
  addNextImage: (imageUrl) => {
    set((state) => ({
      currentImage: state.nextImage,
      nextImage: imageUrl,
      counter: state.counter + 1,
    }));
  },
}));
const db = new Dexie("images");
const anyDb: any = db;
db.version(1).stores({ images: "&id", blobs: "&id" });

class Queue {
  private _queue: string[] = [];
  constructor(private _maxSize: number = Number.MAX_SAFE_INTEGER) {}
  enqueue(item: string) {
    if (this._queue.length >= this._maxSize) {
      this._queue.shift();
    }
    this._queue.push(item);
  }
  dequeue() {
    return this._queue.shift();
  }
  get length() {
    return this._queue.length;
  }
}

export async function getImageCount() {
  return await anyDb.images.count();
}
const maxLoad = 50;
export async function loadImages() {
  let count = 0;
  try {
    const response = await fetch("/images/index.json");
    const data = await response.json();
    for (const filename of data) {
      const exists = await anyDb.images.get(filename);
      if (!exists) {
        const response = await fetch(`/images/${filename}`);
        const blob = await response.blob();
        anyDb.blobs.put({ id: filename, data: blob });
        anyDb.images.put({ id: filename });
        console.log("loaded file", filename, blob.size);
        count++;
        if (count >= maxLoad) {
          break;
        }
      }
    }
  } catch (error) {
    console.log("error loading images");
  }
  return count;
}
async function getImageIds(): Promise<string[]> {
  const filenames = (await anyDb.images.toArray()).map((x: Image) => x.id);
  console.log("all filenames", filenames);
  return filenames;
}
const objectUrlQueue = new Queue();
async function getImage(id: string) {
  const blob = await anyDb.blobs.get(id);
  const url = URL.createObjectURL(blob.data);
  objectUrlQueue.enqueue(url);
  if (objectUrlQueue.length > 2) {
    URL.revokeObjectURL(objectUrlQueue.dequeue()!);
  }
  return url;
}
function chooseRandomImageId(imageIds: string[]) {
  const randomIndex = Math.floor(Math.random() * imageIds.length);
  return imageIds[randomIndex];
}

export async function initialise() {
  const state = useStore.getState();
  const imageCount = await getImageCount();
  if (imageCount < 100) {
    await loadImages();
  }
  const imageIds = await getImageIds();
  const currentImageId = chooseRandomImageId(imageIds);
  const nextImageId = chooseRandomImageId(imageIds);
  const currentImage = await getImage(currentImageId);
  const nextImage = await getImage(nextImageId);

  state.init({
    imageIds,
    currentImage,
    nextImage,
  });
  state.setShowStats(true);
  await sleep(2000);
  state.setShowStats(false);

  loadImagesInBackground();
  const timeout = 3000;
  async function handleNextImage() {
    const nextImageId = chooseRandomImageId(imageIds);
    const nextImage = await getImage(nextImageId);
    useStore.getState().addNextImage(nextImage);
    setTimeout(handleNextImage, timeout);
  }
  setTimeout(handleNextImage, timeout);
}

async function loadImagesInBackground() {
  while (true) {
    const count = await loadImages();
    console.log("loaded", count, "images");
    if (count === 0) {
      break;
    }
  }
}

async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
